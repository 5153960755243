import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import pageTitle from "../components/pageTitle"

export const query = graphql`
  query MyQuery {
    posts: allSanityPage {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`
const SecondPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <h1>
          There be errors (bruk heller GraphyQlErrorList komponent eller noe
          slik)
        </h1>
      </Layout>
    )
  }
  const Titles = ({props}) => {
    return (
        <p>{props.title}</p>
    )
  }

  return (
    <Layout>
      <SEO title="About" />
      <h1 id="first">Hi from the second page</h1>

      {data.posts.edges.map(({ node }) => (
        <Titles props={node} key={node.id} />
      ))}

      <p>Welcome to page 2</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam maximus libero ut quam semper dapibus. Donec sagittis justo quis ipsum sollicitudin condimentum. Donec sapien enim, lacinia sit amet odio pretium, gravida rutrum neque. Nulla consectetur odio ac lectus malesuada, non varius dui fermentum. Quisque sit amet tincidunt nisi. Proin rutrum scelerisque odio, eget efficitur lorem rutrum nec. Suspendisse potenti. Sed in tortor pellentesque magna laoreet semper. In eu lacinia enim. Quisque congue imperdiet urna quis blandit. Nulla augue nulla, porta eget eros at, porta rhoncus urna. Cras rhoncus sit amet lectus sit amet pulvinar. Proin ac nisl nunc. Suspendisse eleifend, odio sit amet blandit pulvinar, mi neque ornare dolor, eget euismod sapien dui vel ligula. Sed at felis vitae erat rhoncus tempus.

Aliquam luctus feugiat massa ut lacinia. Praesent felis nisi, ullamcorper ac tincidunt at, ullamcorper eget quam. Proin quis sapien eget sapien dictum iaculis vitae ut neque. Nam sodales nulla a porta vulputate. Sed et sapien semper, feugiat purus ac, euismod neque. Sed consectetur sapien tellus, a porta lorem pretium at. Nam mollis est quam, ac viverra leo ultrices eu. Proin eget nisl arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce egestas ipsum id est placerat facilisis. Integer interdum sem a quam interdum, vel vulputate sem ultricies. Integer maximus semper sapien sed consequat. Pellentesque sed justo ac felis suscipit mollis vitae in felis.

Etiam eleifend diam nibh, eget vehicula justo lacinia vitae. Ut dapibus blandit ultricies. Morbi malesuada ut elit in posuere. Sed mattis vitae erat non vestibulum. In convallis dapibus ultrices. Morbi porttitor sem quam, ut rhoncus leo tincidunt quis. Vestibulum vel odio sit amet leo tempus efficitur eu ac erat. Integer tincidunt, augue in placerat tincidunt, magna elit finibus ante, eget molestie metus mi vel lectus. Vivamus scelerisque libero lacus, in vestibulum libero pretium sed. Sed mi ligula, blandit vel porta quis, convallis nec mi. Praesent egestas vulputate vehicula. Nam imperdiet massa ultricies placerat fermentum.

Morbi sollicitudin vel dolor ac tempus. Curabitur ac lacus imperdiet, sollicitudin leo nec, venenatis felis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas laoreet nulla at eros imperdiet tristique. Morbi placerat suscipit varius. Suspendisse feugiat egestas augue, at vestibulum nulla dictum et. Proin sit amet eleifend tortor. In ac condimentum tortor. Phasellus semper efficitur dignissim. Donec iaculis interdum laoreet.

Mauris in quam a eros gravida vulputate eget vitae purus. Nulla a tempor felis. Morbi et faucibus odio. Vestibulum vel orci lectus. Suspendisse in urna blandit, dignissim odio nec, viverra lectus. Donec fringilla efficitur fringilla. Aliquam pretium hendrerit nibh, sed ultricies tellus posuere ac. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis lorem nibh, ornare sit amet dui id, finibus malesuada erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam rhoncus purus felis, sed venenatis velit vehicula id. Ut ac consequat dui.

Mauris a magna eget enim cursus fringilla vel et dolor. Quisque sed lacus luctus, tristique massa eu, hendrerit metus. Nam consequat nunc sed neque commodo elementum. Cras lacus nisi, condimentum eu libero eget, condimentum placerat odio. Nunc gravida nisl in ipsum luctus commodo. Fusce eleifend sed massa a scelerisque. Sed sapien massa, ultricies sed eros at, pulvinar commodo lectus. Vivamus nec nunc urna. Etiam viverra sit amet sem id commodo. Cras tincidunt est a neque rutrum, nec commodo sem tempus. Cras ultricies lacus eget eros sodales faucibus at eget lacus. Nam eros velit, dapibus ac mi quis, lacinia tincidunt nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec cursus elit aliquam dictum feugiat. Proin rutrum dictum libero vel maximus.

Praesent ac sapien eu nunc rhoncus porttitor dapibus mattis nisl. Phasellus hendrerit neque velit, vel tristique nulla suscipit vel. Fusce eleifend sem eu sollicitudin ultrices. Nullam sed dui bibendum, sagittis risus eget, vulputate tellus. Fusce ac laoreet metus. Nunc erat leo, molestie quis venenatis congue, ultricies a leo. Etiam sollicitudin massa in gravida finibus. Sed quis nulla venenatis augue condimentum fermentum. Ut eu pulvinar ex. Proin augue velit, cursus non scelerisque quis, placerat in odio. Proin commodo nisl et volutpat consequat. Pellentesque eu vulputate libero, et lobortis elit.</p>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}
export default SecondPage
